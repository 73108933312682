import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M3208 4013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2612 3807 c-1 -58 -6 -79 -17 -83 -13 -5 -15 -39 -17 -208 -2 -185
-2 -192 -8 -81 l-6 120 -2 -101 c-2 -87 -5 -103 -22 -119 -13 -12 -20 -31 -20
-57 0 -37 -6 -47 -54 -86 -19 -15 -19 -12 -5 24 10 28 10 28 -10 9 -12 -10
-21 -13 -21 -7 0 7 -7 20 -15 30 -9 9 -27 31 -41 47 -20 22 -22 23 -10 5 114
-179 153 -301 155 -499 l2 -121 -555 0 -556 0 0 -33 c0 -54 29 -123 70 -170
57 -64 141 -100 251 -105 137 -7 229 29 335 130 l42 40 125 -62 c73 -36 131
-59 138 -54 7 4 10 3 6 -3 -3 -5 23 -24 59 -42 35 -18 64 -35 64 -37 0 -14
-84 -125 -124 -164 -25 -25 -46 -49 -45 -55 0 -11 139 135 139 146 0 4 11 21
25 37 23 28 24 30 7 48 -9 11 -33 27 -52 37 -32 16 -33 17 -7 12 19 -4 26 -2
22 5 -3 5 1 10 9 10 8 0 18 -4 21 -10 3 -5 19 -10 34 -10 19 0 32 -7 39 -22
10 -21 11 -20 5 7 -9 48 -9 49 12 52 11 1 14 0 8 -3 -23 -10 -15 -44 10 -45
17 -1 18 -2 4 -6 -9 -2 -15 -9 -11 -14 3 -5 10 -6 16 -3 6 4 16 0 22 -8 18
-23 42 -28 65 -12 19 14 21 13 21 -1 0 -13 10 -15 58 -13 39 2 56 7 55 16 -2
8 10 12 33 12 27 0 37 5 41 20 4 14 14 20 34 20 16 0 29 5 29 10 0 6 -66 10
-177 10 l-178 0 3 527 c2 506 -1 1023 -6 860z m-132 -672 c0 -5 -5 -3 -10 5
-5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m-170 -635 c8 -5 11
-10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m10 -42 c0
-5 -11 -1 -25 8 -14 9 -25 18 -25 20 0 2 11 -2 25 -8 14 -6 25 -15 25 -20z
m101 -36 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m209 -18 c0
-2 -9 -4 -20 -4 -11 0 -20 4 -20 9 0 5 9 7 20 4 11 -3 20 -7 20 -9z m73 -31
c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m165 0 c-10 -2 -26
-2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M3591 3863 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M3640 3800 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3680 3711 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M2990 3509 l0 -201 103 4 c108 4 137 14 184 67 28 32 47 111 38 158
-10 53 -41 110 -71 129 -34 23 -127 44 -196 44 l-58 0 0 -201z m235 -127 c-3
-3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z"/>
<path d="M3705 3550 c0 -179 -21 -244 -111 -347 -47 -54 -108 -96 -171 -118
l-35 -12 53 -64 c78 -94 86 -102 98 -94 6 3 11 24 11 45 0 21 5 42 10 45 6 3
10 14 10 23 0 9 7 30 15 46 20 38 19 43 -5 21 -23 -20 -28 -11 -6 11 8 8 21
11 29 8 10 -3 20 3 27 15 8 16 8 21 -1 21 -7 0 -2 11 11 24 15 17 21 33 18 50
-3 14 -1 31 4 38 7 9 8 8 4 -5 -4 -12 -2 -17 6 -15 7 3 14 17 16 31 2 15 8 25
13 22 5 -4 7 8 3 30 -4 26 -2 35 8 33 10 -2 12 23 9 122 -5 181 -17 231 -16
70z m12 -157 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-77 -187
c0 -8 -7 -16 -15 -20 -12 -4 -13 -2 -2 14 14 24 17 25 17 6z m-122 -231 c-3
-3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z"/>
<path d="M1738 3603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1668 3583 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M1948 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3735 3486 c2 -43 9 -84 15 -90 8 -8 9 -6 5 6 -4 10 -11 50 -15 90
l-9 73 4 -79z"/>
<path d="M1359 3548 c-8 -27 -5 -36 9 -30 10 4 12 7 4 10 -7 2 -10 8 -6 13 3
5 3 9 0 9 -3 0 -6 -1 -7 -2z"/>
<path d="M1405 3540 c-25 -11 1 -11 35 0 20 6 21 8 5 8 -11 0 -29 -3 -40 -8z"/>
<path d="M1982 3545 c9 -9 108 -44 108 -38 0 5 -53 27 -90 38 -14 4 -22 4 -18
0z"/>
<path d="M1315 3500 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2143 3474 c2 -2 25 -15 53 -29 l49 -25 -29 24 c-16 13 -40 26 -53
29 -13 2 -22 3 -20 1z"/>
<path d="M1187 3433 c-16 -8 -26 -17 -23 -20 3 -3 20 4 38 16 37 24 27 26 -15
4z"/>
<path d="M1129 3381 c-13 -11 -21 -22 -18 -25 3 -3 16 5 29 19 28 30 22 33
-11 6z"/>
<path d="M2265 3390 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2306 3357 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M1060 3318 l-23 -33 31 29 c34 31 37 36 24 36 -5 0 -19 -15 -32 -32z"/>
<path d="M2335 3340 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1003 3263 c-4 -10 -16 -30 -27 -45 -11 -15 -18 -29 -15 -31 2 -3 19
18 37 44 17 27 27 49 22 49 -6 0 -14 -8 -17 -17z"/>
<path d="M1635 3214 c-94 -23 -177 -93 -204 -173 -21 -65 -38 -61 278 -61
l289 0 -16 52 c-21 65 -88 140 -144 163 -43 17 -165 28 -203 19z"/>
<path d="M944 3162 c-15 -10 -49 -78 -64 -129 -7 -24 -16 -43 -20 -43 -13 0
-24 -112 -26 -250 -3 -176 -2 -169 -14 -150 -13 20 -12 39 1 20 7 -11 8 -4 3
25 -4 22 -7 46 -7 53 0 6 -3 12 -7 12 -12 0 -5 -103 8 -127 7 -12 12 -31 12
-43 0 -12 4 -19 9 -16 4 3 7 18 5 33 -4 27 -3 28 5 3 5 -15 4 -30 -3 -38 -21
-28 9 -162 37 -162 5 0 7 -6 4 -14 -3 -8 3 -22 13 -31 11 -10 19 -20 18 -24
-2 -20 4 -32 23 -42 17 -9 24 -8 31 2 7 10 8 9 4 -5 -4 -11 -1 -23 6 -29 9 -6
8 -7 -4 -3 -10 3 -19 2 -22 -2 -8 -13 12 -37 31 -37 13 0 17 -7 16 -22 -2 -17
2 -23 17 -23 14 0 20 -7 20 -23 0 -29 29 -57 57 -57 12 0 23 -8 26 -20 3 -11
12 -20 21 -20 9 0 16 -7 16 -15 0 -8 9 -19 20 -25 17 -9 20 -8 20 7 0 14 9 9
35 -19 18 -21 41 -38 50 -38 9 0 26 -5 37 -11 16 -9 18 -13 7 -20 -11 -6 -9
-9 6 -15 11 -5 20 -3 23 4 6 19 22 14 54 -18 26 -26 37 -30 91 -32 34 0 70 -1
80 0 9 1 17 -3 17 -8 0 -16 237 -13 243 3 2 8 26 11 79 9 73 -2 77 -1 106 28
31 31 52 39 52 19 0 -19 33 -5 36 16 3 23 37 51 42 35 5 -15 39 6 47 29 4 13
12 17 25 14 12 -3 20 0 20 6 0 7 -4 9 -10 6 -5 -3 -10 1 -10 9 0 9 7 16 16 16
25 0 79 59 66 72 -8 8 -17 7 -34 -4 -24 -15 -58 -30 -58 -24 0 2 23 17 50 34
28 17 50 36 50 42 0 7 -13 1 -29 -12 -26 -22 -120 -81 -131 -83 -3 0 -31 -11
-63 -23 -89 -34 -266 -62 -392 -62 -113 0 -243 21 -332 54 -68 24 -177 92
-250 155 -38 32 -75 56 -83 54 -12 -4 -13 -3 -1 5 11 8 3 24 -41 78 -30 38
-65 93 -78 122 -17 37 -26 49 -32 40 -6 -9 -7 -6 -4 8 4 11 -1 42 -10 70 -8
27 -18 60 -20 74 -10 54 -11 250 -1 301 13 70 54 184 84 232 24 39 25 41 7 29z
m-50 -789 c-17 -17 -18 -17 -11 0 4 10 7 24 7 30 0 8 3 8 11 0 9 -9 7 -16 -7
-30z m44 -51 c16 -13 15 -13 -3 -10 -14 2 -21 12 -22 33 -1 18 1 22 4 10 2
-11 12 -26 21 -33z m207 -251 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15
11 -17z m50 -1 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m1115
-10 c0 -5 -2 -10 -4 -10 -2 0 -12 -3 -22 -7 -16 -6 -17 -5 -4 10 17 19 30 22
30 7z m-1165 -18 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m965 -56 c0 -2 -9 -6 -20 -9 -11 -3 -18 -1 -14 4 5 9 34 13 34 5z m37 -22 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M886 3123 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2985 2715 c0 -168 3 -305 8 -305 4 0 8 26 9 58 l1 57 7 -45 c6 -39
7 -35 10 40 3 75 4 79 10 40 4 -25 8 -38 8 -29 1 9 11 23 23 30 11 8 21 23 23
34 3 24 40 32 72 15 12 -7 29 -10 38 -7 10 4 13 1 9 -9 -4 -10 2 -17 15 -21
11 -2 26 -18 33 -34 6 -16 14 -27 17 -25 2 3 21 -12 41 -33 20 -21 51 -46 69
-55 18 -9 30 -21 27 -26 -6 -9 32 -40 50 -40 5 0 3 5 -5 10 -13 8 -12 10 3 10
9 0 17 -4 17 -10 0 -5 9 -10 19 -10 24 0 36 -16 19 -24 -7 -2 29 -4 79 -3 51
1 93 5 93 8 0 4 11 10 25 13 14 4 25 11 25 16 0 6 8 10 18 10 14 0 15 2 2 10
-13 8 -12 10 3 10 10 0 15 5 12 11 -5 7 -59 10 -155 8 l-148 -2 -29 34 c-16
19 -58 72 -94 119 -35 47 -67 87 -70 90 -3 3 -56 73 -119 155 -63 83 -122 159
-132 169 -10 11 -18 23 -18 28 0 4 -3 8 -7 8 -5 0 -8 -137 -8 -305z m325 -175
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m66
-74 c0 -14 -5 -21 -9 -17 -4 4 -5 15 -1 24 9 24 12 21 10 -7z m121 -72 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m30 -20 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M827 2931 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M3545 2910 c-7 -12 -1 -21 89 -128 38 -45 72 -80 74 -77 3 3 -1 11
-9 18 -8 6 -28 30 -44 52 -16 22 -39 50 -50 62 -36 40 -45 52 -45 64 0 8 4 8
15 -1 9 -7 15 -8 15 -2 0 5 -7 13 -16 16 -21 8 -22 8 -29 -4z"/>
<path d="M812 2875 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M801 2824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M813 2760 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3729 2666 c14 -14 27 -26 29 -26 10 0 -26 44 -39 48 -8 3 -3 -7 10
-22z"/>
<path d="M3780 2613 c18 -32 60 -78 60 -67 0 6 -11 21 -25 34 -14 13 -25 29
-25 37 0 7 -5 13 -10 13 -7 0 -7 -6 0 -17z"/>
<path d="M3870 2496 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2542 2335 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2515 2300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2100 1759 c13 -6 28 -15 32 -22 6 -9 8 -9 8 0 0 14 -32 33 -52 32
-7 0 -2 -5 12 -10z"/>
<path d="M2045 1750 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2175 1740 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2041 1712 c-1 -29 38 -49 65 -33 16 9 15 10 -14 11 -24 0 -35 6 -42
23 l-9 22 0 -23z"/>
<path d="M2255 1720 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2600 1715 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3363 1715 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"/>
<path d="M2321 1707 c-1 -24 41 -43 65 -28 17 10 16 11 -6 6 -28 -6 -44 2 -53
25 -4 12 -6 11 -6 -3z"/>
<path d="M2220 1691 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"/>
<path d="M2185 1680 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2488 1683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2740 1680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3133 1683 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3000 1661 c-30 -6 -32 -7 -13 -13 12 -3 30 0 40 8 10 7 16 13 13 12
-3 0 -21 -4 -40 -7z"/>
<path d="M3483 1653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
